import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }
const _hoisted_3 = { class: "mainInputs" }
const _hoisted_4 = { class: "mainInputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_span = _resolveComponent("v-span")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_custom_img_component = _resolveComponent("custom-img-component")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "8" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "titleSt" }, "Componente Foto", -1)),
              _createElementVNode("a", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  class: "text-white",
                  color: "black",
                  onClick: _ctx.removeIcon
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Remover ícone")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  class: "text-white",
                  color: "grey",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal')))
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("Cancelar")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "text-white",
                  color: "#ff5000",
                  onClick: _ctx.store,
                  disabled: _ctx.btn_disabled
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Salvar")
                  ])),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.title,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                label: "Título",
                rules: _ctx.rules,
                "hide-details": "auto",
                onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInputChange()))
              }, null, 8, ["modelValue", "rules"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.description,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.description) = $event)),
                label: "Descrição",
                rules: _ctx.rules,
                "hide-details": "auto",
                onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInputChange()))
              }, null, 8, ["modelValue", "rules"])
            ]),
            _createElementVNode("div", {
              class: "mainInputs",
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.simulateFileInputClick && _ctx.simulateFileInputClick(...args)))
            }, [
              _createVNode(_component_v_file_input, {
                ref: "fileInput",
                class: "hiddenInput",
                modelValue: _ctx.icon,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.icon) = $event)),
                label: "Icone",
                accept: "image/*",
                "prepend-icon": "mdi-camera",
                "hide-details": "auto",
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onFileChange($event)))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.iconName,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.iconName) = $event)),
                class: _normalizeClass(["newFileInput", _ctx.fileErrorColor]),
                label: "Icone"
              }, {
                "prepend-inner": _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    class: _normalizeClass(_ctx.fileErrorIconColor)
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode("mdi-camera")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_component_v_span, { class: "fileErrorMsg" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.fileErrorMsg), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, { cols: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_custom_img_component, { imageSrc: _ctx.imageSrc }, null, 8, ["imageSrc"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}