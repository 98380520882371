import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "component-st" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_span = _resolveComponent("v-span")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          "max-width": "1200",
          color: _ctx.main_card_color,
          class: "mx-auto min-height-card-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { fluid: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  class: "fill-height",
                  align: "center",
                  justify: "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { class: "container-1-col-1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Título",
                          "hide-details": "auto",
                          modelValue: _ctx.header,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.header) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "container-1-col-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_switch, {
                          color: _ctx.movida_color,
                          modelValue: _ctx.last_checklist,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.last_checklist) = $event)),
                          label: 'Buscar Checklist anterior? ' + (_ctx.last_checklist ? 'Sim' : 'Não')
                        }, null, 8, ["color", "modelValue", "label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "container-1-col-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_switch, {
                          color: _ctx.movida_color,
                          modelValue: _ctx.plate_input,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.plate_input) = $event)),
                          label: 'Checklist com placa? ' + (_ctx.plate_input ? 'Sim' : 'Não')
                        }, null, 8, ["color", "modelValue", "label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          "max-width": "1200",
          color: _ctx.main_card_color,
          class: "mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { fluid: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { dense: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-center" }, "Componentes da Tela", -1)),
                        _createVNode(_component_v_card, {
                          color: _ctx.son_card_color,
                          class: "min-height-card-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_draggable, {
                              list: _ctx.screen_components,
                              group: "itens",
                              onChange: _ctx.log,
                              onAdd: _ctx.added,
                              itemKey: "name",
                              class: "list-items"
                            }, {
                              item: _withCtx(({ element, index }) => [
                                _createVNode(_component_v_container, { class: "pa-1" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card, {
                                      class: "mx-12 grab-cursor",
                                      color: _ctx.son_card_color,
                                      onDblclick: ($event: any) => (_ctx.openModal(element))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { class: "mx-auto" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_row, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  cols: "10",
                                                  class: "text-left"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_span, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(element.name.length > 25
                                  ? element.name.slice(0, 22) + "..."
                                  : this.component_jsons[element.id]?.title
                                  ? element.name.length + this.component_jsons[element.id]?.title.length + 3 > 25
                                    ? (element.name + " - " + this.component_jsons[element.id]?.title).slice(0, 22) + "..."
                                    : element.name + " - " + this.component_jsons[element.id]?.title
                                  : element.name), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, {
                                                  cols: "2",
                                                  class: "text-right"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, {
                                                      color: _ctx.close_icon_color,
                                                      onClick: ($event: any) => (_ctx.removeAt(index))
                                                    }, {
                                                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                        _createTextVNode(" mdi-close ")
                                                      ])),
                                                      _: 2
                                                    }, 1032, ["color", "onClick"])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["color", "onDblclick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            }, 8, ["list", "onChange", "onAdd"])
                          ]),
                          _: 1
                        }, 8, ["color"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-center" }, "Lista de componentes", -1)),
                        _createVNode(_component_v_card, {
                          color: _ctx.son_card_color,
                          class: "min-height-card-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_draggable, {
                              list: _ctx.available_componentes,
                              group: { name: 'itens', pull: 'clone', put: false },
                              clone: _ctx.cloneItem,
                              onChange: _ctx.log,
                              itemKey: "name",
                              sort: false,
                              class: "list-items"
                            }, {
                              item: _withCtx(({ element }) => [
                                _createVNode(_component_v_container, { class: "pa-1" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card, {
                                      class: "mx-12 grab-cursor",
                                      color: _ctx.son_card_color
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { class: "mx-auto" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_span, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(element.name.length > 25 ? element.name.slice(0, 22) + "..." : element.name), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["color"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            }, 8, ["list", "clone", "onChange"])
                          ]),
                          _: 1
                        }, 8, ["color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.modal_open,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modal_open) = $event)),
          "onClick:outside": _cache[4] || (_cache[4] = ($event: any) => (_ctx.modal_open = false)),
          width: "auto",
          "content-class": "dialog-model-content",
          scrollable: "",
          transition: "dialog-bottom-transition"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modal_form_component), {
                onCloseModal: _ctx.closeModal,
                onGeneratedJson: _ctx.jsonData,
                onMounted: _ctx.formReady
              }, null, 40, ["onCloseModal", "onGeneratedJson", "onMounted"]))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.success_error_msg) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}