import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_span = _resolveComponent("v-span")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_loading_overlay = _resolveComponent("loading-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_container, { fluid: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            class: "fill-height",
            align: "center",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createElementVNode("h1", { class: "" }, "Itens do checklist", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { align: "end" }, {
                default: _withCtx(() => [
                  _createElementVNode("a", _hoisted_2, [
                    _createVNode(_component_v_btn, {
                      class: "text-white",
                      color: "grey",
                      href: "/items-checklist",
                      disabled: _ctx.btn_disabled
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "text-white",
                      color: "#D44E11",
                      onClick: _ctx.newItem,
                      disabled: _ctx.btn_disabled
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Novo Item")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "text-white",
                      color: _ctx.movida_color,
                      onClick: _ctx.save,
                      disabled: _ctx.btn_disabled,
                      loading: _ctx.loading
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Salvar")
                      ])),
                      _: 1
                    }, 8, ["color", "onClick", "disabled", "loading"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_container, {
      fluid: "",
      hidden: _ctx.hide_container
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { color: _ctx.main_card_color }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              class: "fill-height",
              align: "center",
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      color: _ctx.son_card_color,
                      class: "min-height-card"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_draggable, {
                          list: _ctx.data_list,
                          itemKey: "id",
                          class: "list-items",
                          ref: "itemsList"
                        }, {
                          item: _withCtx(({ element }) => [
                            _createVNode(_component_v_container, { class: "pa-1" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  class: "mx-12 grab-cursor",
                                  color: _ctx.son_card_color,
                                  onDblclick: ($event: any) => (_ctx.showItemDetails(element))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "mx-auto" }, {
                                      default: _withCtx(() => [
                                        (element.status)
                                          ? (_openBlock(), _createBlock(_component_v_icon, {
                                              key: 0,
                                              color: "green"
                                            }, {
                                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                _createTextVNode(" mdi-check-circle ")
                                              ])),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createBlock(_component_v_icon, {
                                              key: 1,
                                              color: "red"
                                            }, {
                                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                _createTextVNode(" mdi-close-circle ")
                                              ])),
                                              _: 1
                                            })),
                                        _createVNode(_component_v_span, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(element.name.length > 48 ? element.name.slice(0, 45) + "..." : element.name), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["color", "onDblclick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        }, 8, ["list"])
                      ]),
                      _: 1
                    }, 8, ["color"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    (_ctx.hidden_control)
                      ? (_openBlock(), _createBlock(_component_v_card, {
                          key: 0,
                          color: _ctx.son_card_color,
                          class: "min-height-card list-items"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_container, { class: "pa-1" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  class: "input-borders",
                                  label: "Nome do item",
                                  "hide-details": "auto",
                                  modelValue: _ctx.item_name,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item_name) = $event)),
                                  rules: _ctx.name_rules,
                                  ref: "ref_item_name"
                                }, null, 8, ["modelValue", "rules"]),
                                _createVNode(_component_v_combobox, {
                                  modelValue: _ctx.status,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.status) = $event)),
                                  items: _ctx.status_choices,
                                  density: "compact",
                                  label: "Status",
                                  rules: _ctx.status_rules,
                                  ref: "ref_status"
                                }, null, 8, ["modelValue", "items", "rules"]),
                                _createVNode(_component_v_combobox, {
                                  modelValue: _ctx.damage,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.damage) = $event)),
                                  items: _ctx.damage_choices,
                                  density: "compact",
                                  label: "Avaria",
                                  rules: _ctx.damage_rules,
                                  ref: "ref_damage"
                                }, null, 8, ["modelValue", "items", "rules"]),
                                _createElementVNode("h3", {
                                  style: _normalizeStyle({ color: _ctx.movida_color })
                                }, "Selecione os grupos que percentem ao Item do checklist", 4),
                                _createVNode(_component_v_combobox, {
                                  ref: "groupComboboxElement",
                                  clearable: "",
                                  multiple: "",
                                  modelValue: _ctx.selected_groups,
                                  "onUpdate:modelValue": [
                                    _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected_groups) = $event)),
                                    _ctx.onSelectedGroupsChange
                                  ],
                                  items: _ctx.all_groups,
                                  density: "compact",
                                  label: "Grupos",
                                  "onClick:clear": _ctx.onSelectedGroupsClear,
                                  "onUpdate:focused": _ctx.onGroupsComboboxFocusChange
                                }, {
                                  selection: _withCtx(() => _cache[12] || (_cache[12] = [])),
                                  "prepend-item": _withCtx(() => [
                                    _createVNode(_component_v_list, {
                                      items: _ctx.groupOptions,
                                      "onClick:select": _ctx.onGroupComboboxOption
                                    }, {
                                      item: _withCtx(({ props }) => [
                                        _createVNode(_component_v_list_item, _mergeProps(props, { title: "" }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_checkbox, {
                                              modelValue: _ctx.groupOptionModel,
                                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.groupOptionModel) = $event)),
                                              label: props.title,
                                              value: props.value,
                                              style: { height: '3.5em' },
                                              readonly: ""
                                            }, null, 8, ["modelValue", "label", "value"])
                                          ]),
                                          _: 2
                                        }, 1040)
                                      ]),
                                      _: 1
                                    }, 8, ["items", "onClick:select"])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "items", "onClick:clear", "onUpdate:modelValue", "onUpdate:focused"]),
                                _createVNode(_component_v_chip_group, {
                                  column: "",
                                  class: "px-3"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected_groups, (group) => {
                                      return (_openBlock(), _createBlock(_component_v_chip, { key: group }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(group), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _: 1
    }, 8, ["hidden"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.success_error_msg) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"]),
    _createVNode(_component_loading_overlay, { ref: "loadingOverlay" }, null, 512)
  ], 64))
}