import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "horizontalScrollLocked" }
const _hoisted_2 = { class: "d-flex justify-space-between" }
const _hoisted_3 = { class: "text-decoration-none" }
const _hoisted_4 = { class: "mainInputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_custom_img_component = _resolveComponent("custom-img-component")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "8" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _cache[11] || (_cache[11] = _createElementVNode("h1", { class: "titleSt" }, "Componente Opções Personalizadas", -1)),
                _createElementVNode("a", _hoisted_3, [
                  _createVNode(_component_v_btn, {
                    class: "text-white",
                    color: "grey",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal')))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Cancelar")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    class: "text-white",
                    color: "#ff5000",
                    onClick: _ctx.store,
                    disabled: _ctx.btn_disabled
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("Salvar")
                    ])),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.title,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                  label: "Título",
                  rules: _ctx.rules,
                  "hide-details": "auto",
                  onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInputChange()))
                }, null, 8, ["modelValue", "rules"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_v_switch, {
                  color: _ctx.movida_color,
                  modelValue: _ctx.hasObsField,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hasObsField) = $event)),
                  onChange: _ctx.verifyBtnDisabled,
                  label: 'Possui campo Observação? ' + (_ctx.hasObsField ? 'Sim' : 'Não')
                }, null, 8, ["color", "modelValue", "onChange", "label"])
              ]),
              _createElementVNode("div", null, [
                _cache[18] || (_cache[18] = _createElementVNode("hr", null, null, -1)),
                _cache[19] || (_cache[19] = _createElementVNode("h2", null, "Lista de perguntas e opções", -1)),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.id,
                      class: "mainInputs"
                    }, [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                outlined: "",
                                class: "questionElement1",
                                modelValue: item.question,
                                "onUpdate:modelValue": ($event: any) => ((item.question) = $event),
                                label: "Pergunta",
                                rules: _ctx.rules,
                                "hide-details": "auto",
                                onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInputChange()))
                              }, {
                                append: _withCtx(() => [
                                  (item.id != 1)
                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                        key: 0,
                                        color: "red",
                                        onClick: ($event: any) => (_ctx.removeItem(item.id))
                                      }, {
                                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                                          _createTextVNode(" mdi-minus ")
                                        ])),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "rules"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "11" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                outlined: "",
                                class: "questionElement2",
                                modelValue: item.positive,
                                "onUpdate:modelValue": ($event: any) => ((item.positive) = $event),
                                label: "Opção positiva",
                                rules: _ctx.rules,
                                "hide-details": "auto",
                                onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onInputChange()))
                              }, {
                                append: _withCtx(() => _cache[13] || (_cache[13] = [])),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "rules"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_col, { cols: "1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                class: "questionElement2",
                                modelValue: item.photo_positive,
                                "onUpdate:modelValue": ($event: any) => ((item.photo_positive) = $event),
                                onChange: ($event: any) => (_ctx.onlyOneCheckedBox(item.id, 'positive'))
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "11" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                outlined: "",
                                class: "questionElement2",
                                modelValue: item.negative,
                                "onUpdate:modelValue": ($event: any) => ((item.negative) = $event),
                                label: "Opção negativa",
                                rules: _ctx.rules,
                                "hide-details": "auto",
                                onKeyup: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onInputChange()))
                              }, {
                                append: _withCtx(() => _cache[14] || (_cache[14] = [])),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "rules"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_col, { cols: "1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                class: "questionElement2",
                                modelValue: item.photo_negative,
                                "onUpdate:modelValue": ($event: any) => ((item.photo_negative) = $event),
                                onChange: ($event: any) => (_ctx.onlyOneCheckedBox(item.id, 'negative'))
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "11" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                outlined: "",
                                class: "questionElement3",
                                modelValue: item.neutral,
                                "onUpdate:modelValue": ($event: any) => ((item.neutral) = $event),
                                label: "Opção neutra",
                                rules: _ctx.rules,
                                "hide-details": "auto",
                                onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onInputChange()))
                              }, {
                                append: _withCtx(() => _cache[15] || (_cache[15] = [])),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "rules"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_col, { cols: "1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                class: "questionElement2",
                                modelValue: item.photo_neutral,
                                "onUpdate:modelValue": ($event: any) => ((item.photo_neutral) = $event),
                                onChange: ($event: any) => (_ctx.onlyOneCheckedBox(item.id, 'neutral'))
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _cache[16] || (_cache[16] = _createElementVNode("hr", { class: "hrMargin" }, null, -1))
                    ]))
                  }), 128)),
                  _createVNode(_component_v_btn, { onClick: _ctx.addItem }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("Adicionar pergunta")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, { cols: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_custom_img_component, { imageSrc: _ctx.imageSrc }, null, 8, ["imageSrc"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[20] || (_cache[20] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}