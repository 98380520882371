import { BaseService } from "../BaseService";
import { ItemChecklistStoreRequest } from "@/dtos/itemChecklist/ItemChecklistStoreRequest";
import { ItemChecklistUpdateOrderRequest } from "@/dtos/itemChecklist/ItemChecklistUpdateOrderRequest";
import { ItemChecklistUpdateGroupsRequest } from "@/dtos/itemChecklist/ItemChecklistUpdateGroupsRequest";
import { ItemChecklistSaveItensByGroupRequest } from "@/dtos/itemChecklist/ItemChecklistSaveItensByGroupRequest";
import { ItemChecklistIndexResponse } from "@/dtos/itemChecklist/ItemChecklistIndexResponse";
class ItemChecklistService extends BaseService {
  async getAll(needle: string | null = null) {
    let route = `/itemsChecklist`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<ItemChecklistIndexResponse>(route, this.getConfigHeaders());
    return response.data;
  }

  async getAllGroups(needle: string | null = null) {
    let route = `/itemsChecklist/listAllGroups/`;
    if (needle) {
      route = route + `${needle}`;
    }
    const response = await this.instance.get<ItemChecklistIndexResponse>(route, this.getConfigHeaders());
    return response.data;
  }

  async getActivesWithGroup(needle: string | null = null) {
    let route = `/itemsChecklist/activesWithGroup/`;
    if (needle) {
      route = route + `${needle}`;
    }
    const response = await this.instance.get<ItemChecklistIndexResponse>(route, this.getConfigHeaders());
    return response.data;
  }

  async updateOrder(dataRequest: ItemChecklistUpdateOrderRequest) {
    const route = `/itemsChecklist/saveOrder`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }

  async update(dataRequest: ItemChecklistStoreRequest, id: number) {
    const route = `/itemsChecklist/${id}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }

  async updateGroups(dataRequest: ItemChecklistUpdateGroupsRequest, id: number) {
    const route = `/itemsChecklist/attachGroups/${id}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }

  async storeNew(dataRequest: ItemChecklistStoreRequest) {
    const route = `/itemsChecklist/`;
    const response = await this.post(route, dataRequest);

    return response.data;
  }

  async updateItensByGroup(dataRequest: ItemChecklistSaveItensByGroupRequest, groupId: string) {
    const route = `/itemsChecklist/attachItensToGroup/${groupId}`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }
}

export default new ItemChecklistService("", "");
