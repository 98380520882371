import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "" }, "Novo tipo", -1)),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "grey",
          href: "/tipos-checklist"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Cancelar")
          ])),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "#FF5000",
          onClick: _ctx.store,
          disabled: _ctx.btn_disabled
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Salvar")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.type_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.type_name) = $event)),
        label: "Nome",
        rules: _ctx.rules,
        "hide-details": "auto"
      }, null, 8, ["modelValue", "rules"])
    ]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}