import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "container-wrapper" }
const _hoisted_3 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_custom_json_component = _resolveComponent("custom-json-component")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_container, { fluid: "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_row, {
              class: "fill-height",
              align: "center",
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("h1", { class: "" }, "Editar checklist", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_col, { align: "end" }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", _hoisted_3, [
                      _createVNode(_component_v_btn, {
                        class: "text-white",
                        color: "grey",
                        href: "/tipos-checklist"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("Cancelar")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        class: "text-white",
                        color: "#FF5000",
                        onClick: _ctx.update,
                        disabled: _ctx.btn_disabled
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Salvar")
                        ])),
                        _: 1
                      }, 8, ["onClick", "disabled"]),
                      _createVNode(_component_v_btn, {
                        class: "text-white",
                        color: "orange-darken-3",
                        onClick: _ctx.downloadJson,
                        disabled: _ctx.btn_disabled
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Salvar e baixar json")
                        ])),
                        _: 1
                      }, 8, ["onClick", "disabled"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_v_container, { fluid: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            "max-width": "1200",
            color: _ctx.main_card_color,
            class: "mx-auto min-height-card-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Nome",
                rules: _ctx.rules,
                "hide-details": "auto",
                modelValue: _ctx.type_name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.type_name) = $event))
              }, null, 8, ["rules", "modelValue"])
            ]),
            _: 1
          }, 8, ["color"])
        ]),
        _: 1
      }),
      _createVNode(_component_custom_json_component, { ref: "CustomJsonComponentRef" }, null, 512)
    ]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}