import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-center align-center min-h-100vh" }
const _hoisted_2 = { class: "text-left neutral_900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_flex = _resolveComponent("v-flex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_flex, { class: "justify-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              elevation: "5",
              class: "pa-md-16 pa-sm-4 px-md-16 px-sm-4"
            }, {
              default: _withCtx(() => [
                (_ctx.state.showInactivityAlert)
                  ? (_openBlock(), _createBlock(_component_v_alert, {
                      key: 0,
                      class: "mb-4",
                      density: "compact",
                      type: "warning",
                      border: "start",
                      closable: ""
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Sua sessão expirou após 30 minutos de inatividade."),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode("Realize um novo login")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.state.showErrorMessage)
                  ? (_openBlock(), _createBlock(_component_v_alert, {
                      key: 1,
                      closable: "",
                      density: "compact",
                      type: "error",
                      text: "Erro ao tentar fazer login!"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h1", _hoisted_2, "Acesse o " + _toDisplayString(_ctx.appName), 1),
                        _cache[6] || (_cache[6] = _createElementVNode("p", { color: "neutral_300" }, "Para continuar, digite seu usuário e senha Movida.", -1))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_form, {
                  modelValue: _ctx.valid,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event)),
                  name: "form-login",
                  onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onSubmit()), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: _ctx.state.loading,
                                  modelValue: _ctx.form.username,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
                                  modelModifiers: { trim: true },
                                  type: "text",
                                  label: "Usuário",
                                  variant: "outlined",
                                  outlined: "",
                                  dense: ""
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.form.password,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
                                  modelModifiers: { trim: true },
                                  variant: "outlined",
                                  label: "Senha",
                                  "append-inner-icon": _ctx.state?.isVisiblePass ? 'mdi-eye-outline' : 'mdi-eye-off-outline',
                                  "onClick:appendInner": _cache[2] || (_cache[2] = () => (_ctx.state = { isVisiblePass: !_ctx.state?.isVisiblePass })),
                                  type: _ctx.state?.isVisiblePass ? 'text' : 'password',
                                  outlined: "",
                                  dense: "",
                                  disabled: _ctx.state.loading
                                }, null, 8, ["modelValue", "append-inner-icon", "type", "disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "12",
                              class: "justify-center"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  disabled: _ctx.state.loading,
                                  loading: _ctx.state.loading,
                                  type: "submit",
                                  color: "#ff5000",
                                  block: ""
                                }, {
                                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                                    _createTextVNode(" Entrar ")
                                  ])),
                                  _: 1
                                }, 8, ["disabled", "loading"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}