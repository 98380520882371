<template>
  <groups-checklist-itens-list-component />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import GroupsChecklistItensListComponent from "@/components/groupsChecklistItensList/GroupsChecklistItensListComponent.vue";
import * as services from "@/services";
import { ItemChecklist } from "@/models/itemChecklist/ItemChecklist";
import { ItemChecklistIndexResponse } from "@/dtos/itemChecklist/ItemChecklistIndexResponse";

@Options({
  components: {
    GroupsChecklistItensListComponent,
  },
})
export default class GroupsChecklistItensListIndexView extends Vue {}
</script>
