<template>
  <div class="root">
    <h2>Recuperar conta</h2>
    <p>
      <input type="text" placeholder="Email" v-model="form.username" />
    </p>
    <p>
      <input type="password" v-if="form.username.length > 4" placeholder="Password" v-model="form.password" />
    </p>
    <button @click="doLogin" v-if="form.username.length > 4 && form.password.length > 4">Acessar</button>
  </div>
</template>

<script lang="ts">
import { LoginRequest } from "@/services/auth/requests/LoginRequest";
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";

@Options({
  components: {},
})
export default class RecoveryView extends Vue {
  public form: LoginRequest = { access_token: "", username: "", password: "", recaptcha: "" };

  async mounted(): Promise<void> {
    if (process.env.VUE_APP_USERNAME && process.env.VUE_APP_PASSWORD) {
      this.form.username = process.env.VUE_APP_USERNAME;
      this.form.password = process.env.VUE_APP_PASSWORD;
    }
  }

  async doLogin(): Promise<void> {
    const loginRequest: LoginRequest = {
      recaptcha: this.$store.getters.tokens.recaptcha,
      username: this.form.username,
      password: this.form.password,
      access_token: this.$store.getters.tokens.bearer,
    } as LoginRequest;

    const userResponse = await services.AuthService.getInstance("").doAuthUser(loginRequest);

    if (userResponse.status) {
      this.$store.commit("SET_USER_TOKEN", userResponse.data.token);
      this.$router.push("admin");
    } else {
      alert("erro aso logar");
    }
  }
}
</script>
<style lang="css">
/* .root {
  width: 400px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  margin-top: 100px;
  border-radius: 20px;
}
input {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
  padding: 5px 0;
  margin: 10px 0 5px 0;
  width: 100%;
}
button {
  background-color: #3498db;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  color: white;
} */
</style>
