import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_loading_overlay = _resolveComponent("loading-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_container, { fluid: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            class: "fill-height",
            align: "center",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("h1", { class: "" }, "Itens do checklist por Grupo", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { align: "end" }, {
                default: _withCtx(() => [
                  _createElementVNode("a", _hoisted_2, [
                    _createVNode(_component_v_btn, {
                      class: "text-white",
                      color: "grey",
                      href: "/group-items-checklist",
                      disabled: _ctx.btn_disabled
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "text-white",
                      color: _ctx.movida_color,
                      onClick: _ctx.save,
                      disabled: _ctx.btn_disabled,
                      loading: _ctx.loading
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Salvar")
                      ])),
                      _: 1
                    }, 8, ["color", "onClick", "disabled", "loading"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_container, {
      fluid: "",
      hidden: _ctx.hide_container
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { color: _ctx.main_card_color }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              class: "fill-height",
              align: "top",
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "max-height-col" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.groupFilter,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupFilter) = $event)),
                      counter: 2,
                      clearable: "",
                      label: "Digite aqui o grupo que deseja buscar",
                      onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterGroupList())),
                      "onClick:clear": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterGroupList()))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_v_card, {
                      color: _ctx.son_card_color,
                      class: "max-height-card",
                      style: {"overflow-y":"auto"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_groups, (group) => {
                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                key: group,
                                onClick: ($event: any) => (_ctx.getChecklistItems(group))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(group), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["color"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "max-height-col" }, {
                  default: _withCtx(() => [
                    (_ctx.hidden_control)
                      ? (_openBlock(), _createBlock(_component_v_card, {
                          key: 0,
                          color: _ctx.son_card_color,
                          class: "list-items"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_container, { class: "pa-1" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", {
                                  style: _normalizeStyle({ color: _ctx.movida_color })
                                }, "Selecione os itens do checklist que percentem ao grupo \"" + _toDisplayString(_ctx.current_seleted_group) + "\"", 5),
                                _createVNode(_component_v_combobox, {
                                  ref: "itemComboboxElement",
                                  clearable: "",
                                  multiple: "",
                                  modelValue: _ctx.selected_itens,
                                  "onUpdate:modelValue": [
                                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selected_itens) = $event)),
                                    _ctx.onSelectedItensChange
                                  ],
                                  items: _ctx.all_itens_names,
                                  density: "compact",
                                  label: "Itens",
                                  "onClick:clear": _ctx.onSelectedChecklistItensClear,
                                  "onUpdate:focused": _ctx.onItensComboboxFocusChange
                                }, {
                                  selection: _withCtx(() => _cache[8] || (_cache[8] = [])),
                                  "prepend-item": _withCtx(() => _cache[9] || (_cache[9] = [])),
                                  _: 1
                                }, 8, ["modelValue", "items", "onClick:clear", "onUpdate:modelValue", "onUpdate:focused"]),
                                _createVNode(_component_v_chip_group, {
                                  column: "",
                                  class: "px-3"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected_itens, (item) => {
                                      return (_openBlock(), _createBlock(_component_v_chip, { key: item }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _: 1
    }, 8, ["hidden"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.success_error_msg) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"]),
    _createVNode(_component_loading_overlay, { ref: "loadingOverlay" }, null, 512)
  ], 64))
}