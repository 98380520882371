import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "status-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-flex justify-space-between" }, [
      _createElementVNode("h1", { class: "" }, "Tipos de combustíveis")
    ], -1)),
    _createVNode(_component_v_data_table, {
      headers: _ctx.headers,
      items: _ctx.items,
      "items-per-page": -1,
      loading: _ctx.isLoadingTable,
      "item-value": "id",
      class: "elevation-1",
      "hide-default-footer": ""
    }, {
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_switch, {
            disabled: _ctx.isLoadingTable,
            modelValue: item.status,
            "onUpdate:modelValue": [($event: any) => ((item.status) = $event), ($event: any) => (_ctx.updateFuel(item.id, { status: item.status }))],
            "false-value": 0,
            "true-value": 1,
            color: "orange",
            label: item.status ? 'Ativo' : 'Inativo'
          }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "label"])
        ])
      ]),
      _: 2
    }, 1032, ["headers", "items", "loading"])
  ], 64))
}