import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-flex justify-space-between" }, [
      _createElementVNode("h1", { class: "" }, "Tipos de checklist")
    ], -1)),
    _createVNode(_component_v_row, { class: "mb-3 flex justify-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "7" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              label: "Buscar...",
              "prepend-inner-icon": "mdi-magnify",
              clearable: "",
              "single-line": "",
              density: "compact",
              variant: "solo",
              "hide-details": "",
              modelValue: _ctx.needleSearch,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.needleSearch) = $event)),
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "5",
          align: 'right'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, { onClick: _ctx.goToCreate }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Novo")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
        _ctx.setItemsPerPage
      ],
      "on-c": "",
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.isLoadingTable,
      "item-value": "type_name",
      class: "elevation-1",
      "hide-default-footer": "",
      to: "asd"
    }, {
      [`item.options`]: _withCtx(({ item }) => [
        _createVNode(_component_v_menu, {
          bottom: "",
          left: ""
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps({
              dark: "",
              icon: ""
            }, props, { style: {"box-shadow":"none"} }), {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("mdi-dots-vertical")
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, {
                  onClick: ($event: any) => (_ctx.goToUpdate(item.id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Editar")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["items-per-page", "onUpdate:itemsPerPage", "headers", "items", "loading"]),
    _createVNode(_component_v_row, {
      class: "mt-2 d-flex align-center",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_pagination, {
            modelValue: _ctx.currentPage,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
              _ctx.loadPage
            ],
            length: _ctx.numberOfPages,
            "total-visible": 7,
            onNext: _ctx.nextPage,
            onPrev: _ctx.prevPage,
            color: "#ff5000"
          }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados ", 1)
        ])
      ]),
      _: 1
    })
  ], 64))
}