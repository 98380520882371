<template>
  <div class="d-flex justify-space-between">
    <v-container fluid>
      <v-row class="fill-height" align="center" justify="center">
        <v-col>
          <h1 class="">Itens do checklist por Grupo</h1>
        </v-col>
        <v-col align="end">
          <a class="text-decoration-none">
            <v-btn class="text-white" color="grey" href="/group-items-checklist" v-bind:disabled="btn_disabled">Cancelar</v-btn>
            <v-btn class="text-white" :color="movida_color" @click="save" v-bind:disabled="btn_disabled" :loading="loading">Salvar</v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <v-container fluid :hidden="hide_container">
    <v-card :color="main_card_color">
      <v-row class="fill-height" align="top" justify="center">
        <v-col class="max-height-col">
          <v-text-field v-model="groupFilter" :counter="2" clearable label="Digite aqui o grupo que deseja buscar" @keyup="filterGroupList()" @click:clear="filterGroupList()"></v-text-field>
          <v-card :color="son_card_color" class="max-height-card" style="overflow-y: auto">
            <v-list>
              <v-list-item v-for="group in all_groups" :key="group" @click="getChecklistItems(group)">
                <v-list-item-title>{{ group }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col class="max-height-col">
          <v-card :color="son_card_color" class="list-items" v-if="hidden_control">
            <v-container class="pa-1">
              <h3 :style="{ color: movida_color }">Selecione os itens do checklist que percentem ao grupo "{{ current_seleted_group }}"</h3>
              <v-combobox ref="itemComboboxElement" clearable multiple v-model="selected_itens" :items="all_itens_names" density="compact" label="Itens" @click:clear="onSelectedChecklistItensClear" @update:model-value="onSelectedItensChange" @update:focused="onItensComboboxFocusChange">
                <template v-slot:selection></template>
                <template v-slot:prepend-item></template>
              </v-combobox>
              <v-chip-group column class="px-3">
                <v-chip v-for="item in selected_itens" :key="item">
                  {{ item }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ success_error_msg }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
  <loading-overlay ref="loadingOverlay" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import draggable from "vuedraggable";
import { ItemChecklist } from "@/models/itemChecklist/ItemChecklist";
import LoadingOverlay from "@/components/LoadingOverlay/LoadingOverlay.vue";

@Options({
  components: {
    draggable,
    LoadingOverlay,
  },
})
export default class ItemChecklistIndexComponent extends Vue {
  public data_list: any = [];
  public status_choices = ["Inativo", "Ativo"];
  public damage_choices = ["Não", "Sim"];
  public status = "";
  public damage = "";
  public item_id = 0;
  public item_name = "";
  public current_seleted_group = "";
  public groupFilter = "";
  public selected_itens: string[] = [];
  public all_groups = [];
  public all_groups_full_list = [];
  public all_itens_names = ["Selecionar todos"];
  public hidden_control = false;
  public snackbar = false;
  public btn_disabled = false;
  public loading = false;
  public success_error_msg = "";
  public main_card_color = "#ebeae8";
  public son_card_color = "#f5f5f5";
  public movida_color = "#ff5000";
  public hide_container = true;
  public itemOptions = [];
  public groupOptionModel: string[] = [];

  mounted(): void {
    this.willMountDataPage();
  }

  /*
    Oculta/exibe a animação de loading e a parte onde os dados são exibidos até que os dados sejam carregados
    e também torna os botões clicáveis ou não
  */
  async loadingOverlay(loading = true): Promise<void> {
    const loadingOverlay = this.$refs.loadingOverlay as any;
    if (loading) {
      loadingOverlay.show();
    } else {
      loadingOverlay.hide();
    }

    this.btn_disabled = loading;
    this.hide_container = loading;
  }

  async willMountDataPage(): Promise<void> {
    // exibe a animação de loading enquanto os dados são carregados
    await this.loadingOverlay(true);

    const response = await services.ItemChecklistService.getAll();
    const all_groups = await services.ItemChecklistService.getAllGroups("");

    if (response.data) {
      // Ordena os dados com base no campo 'order'
      this.data_list = response.data.sort((a, b) => a.order - b.order);
      this.data_list.forEach((item: any) => {
        this.all_itens_names.push(item.name as never);
      });
    }

    if (all_groups.data) {
      this.all_groups = [...all_groups.data] as any;
      this.all_groups_full_list = [...this.all_groups];
    }

    // oculta a animação de loading após os dados serem carregados
    await this.loadingOverlay(false);

    // scroll top para lista de checkist itens
    const itemsListElement = this.$refs.itemsList as any;
    itemsListElement?.targetDomElement?.scrollTo({ top: 0 });

    // scroll top para opções do item checklist
    if (this.hidden_control) {
      this.hidden_control = false;
      this.$nextTick(() => {
        this.hidden_control = true;
      });
    }
  }

  closeSnack() {
    this.snackbar = false;
  }

  onSelectedChecklistItensClear() {
    const itemComboboxElement = this.$refs.itemComboboxElement as any;
    itemComboboxElement.blur();
  }

  onItensComboboxFocusChange(focused: boolean) {
    if (focused) {
      this.onSelectedItensChange();
    }
  }

  onSelectedItensChange() {
    if ((this.selected_itens as string[]).includes("Selecionar todos")) {
      // Quando o usuário clica em Selecionar todos, adiciona todos os itens
      this.selected_itens = [...this.all_itens_names];
    }

    // Não permite que o usuário tente adicionar um item (digitando) que não existe
    this.selected_itens = this.selected_itens.filter((item) => this.all_itens_names.includes(item));

    // Encontra o índice do elemento "Selecionar todos"
    const index = this.selected_itens.indexOf("Selecionar todos");
    // Remover o elemento do array
    if (index !== -1) {
      this.selected_itens.splice(index, 1);
    }
  }

  filterGroupList() {
    this.all_groups = Object.values(this.all_groups_full_list);
    if (this.groupFilter) {
      this.all_groups = Object.values(this.all_groups).filter((group: string) => group.toLowerCase().includes(this.groupFilter.toLowerCase()));
    }
  }

  async getChecklistItems(group: any): Promise<any> {
    await this.loadingOverlay(true);
    const response = await services.ItemChecklistService.getActivesWithGroup(group);
    this.current_seleted_group = group;

    // limpa os itens selecionados caso o usuário clique em outro grupo
    this.selected_itens = [];

    // adiciona os itens que já estão selecionados no banco aos itens selecionados no front
    response.data?.forEach((item: any) => {
      this.selected_itens.push(item.name as never);
    });

    await this.loadingOverlay(false);
    this.hidden_control = true;
  }

  async checkValidations(is_storing = false): Promise<boolean> {
    let validation_ok = true;

    if (this.item_id == 0 && !is_storing) {
      return validation_ok;
    }

    let item_name = (await this.$refs.ref_item_name) as any;
    let status = (await this.$refs.ref_status) as any;
    let damage = (await this.$refs.ref_damage) as any;

    item_name = await item_name.validate();
    status = await status.validate();
    damage = await damage.validate();

    const validation_list: any[] = [];
    validation_list.push(item_name.length);
    validation_list.push(status.length);
    validation_list.push(damage.length);

    validation_ok = !validation_list.some((v) => v > 0);

    const is_any_selected_group_not_in_all_groups = this.selected_itens.some((group) => !this.all_groups.includes(group as never));
    const item_groups = this.data_list.find((item: any) => item.item_id === this.item_id)?.groups;

    if (is_any_selected_group_not_in_all_groups) {
      await this.showSnack("Algum grupo selecionado não está na lista de grupos, remova antes de salvar");
      validation_ok = false;
    } else if (item_groups?.length > 0 && this.selected_itens.length == 0) {
      await this.showSnack("Não é possível remover todos os grupos, selecione pelo menos um");
      validation_ok = false;
    }

    return validation_ok;
  }

  async save(): Promise<void> {
    try {
      this.btn_disabled = true;

      if (this.hidden_control) {
        const itens_ids = {
          itens_ids: this.data_list
            .filter((item: any) => {
              return this.selected_itens.includes(item.name);
            })
            .map((item: any) => item.item_id),
        };

        const update_group_itens_response = await services.ItemChecklistService.updateItensByGroup(itens_ids, this.current_seleted_group);

        this.groupFilter = "";

        await this.showSnack(update_group_itens_response.msg);
        await this.willMountDataPage();
      }
    } catch (error) {
      this.btn_disabled = false;
      this.loading = false;
      await this.showSnack("Algo deu errado ao salvar os dados, tente novamente");
    }

    this.btn_disabled = false;
  }

  async showSnack(msg: string): Promise<void> {
    if (msg != "") {
      this.success_error_msg = msg;
      this.snackbar = true;
    }
  }
}
</script>

<style lang="css">
.min-height-card {
  min-height: 114px;
}

.max-height-card {
  max-height: 700px;
}

.max-height-col {
  max-height: 800px;
}

.grab-cursor {
  cursor: grab;
}

.list-items {
  height: 776px;
  overflow-y: auto !important;
}

.input-borders {
  padding-bottom: 21px;
}

.custom-chip {
  margin-right: 15px;
  width: 52px;
}
</style>
